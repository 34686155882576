<template>
    <div class="">
        <div class="search-tab-wrapper">
            <div class="search-tab-wrapper-inner container">
                <button
                    class="search-tab"
                    :class="{ 'search-tab-active': selectedIndex === 'products_default' }"
                    @click="swapIndex('products_default')"
                >
                    Listings
                </button>

                <button
                    class="search-tab"
                    :class="{ 'search-tab-active': selectedIndex === 'pages_default' }"
                    @click="swapIndex('pages_default')"
                >
                    Pages
                </button>
            </div>
        </div>
        <div class="container">
            <Listings v-if="selectedIndex === 'products_default'" />
            <Pages v-if="selectedIndex === 'pages_default'" />
        </div>
    </div>
</template>

<script>
import Listings from './Listings.vue';
import Pages from './Pages.vue';

export default {
    components: {
        Listings,
        Pages,
    },

    props: {
        selectedIndex: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            query: '',
        };
    },

    methods: {
        swapIndex(index) {
            this.$emit('swap-index', index);
        },
    },
};
</script>
