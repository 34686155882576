import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

const customSelect = (field) => {
    new Choices(field, {
        searchEnabled: false,
        itemSelectText: '',
    });
};

const customFileUpload = (field) => {
    const wrapper = field.querySelector('.input');
    const input = field.querySelector('input');

    input.addEventListener('change', (e) => {
        wrapper.dataset.text = e.target.files && e.target.files.length > 0
            ? `${e.target.files.length} files selected`
            : wrapper.dataset.text = 'Choose a file...';
    });

    const changeEvent = new Event('change');
    input.dispatchEvent(changeEvent);
};

export const setupForm = (form) => {
    const selects = Array.from(
        form.querySelectorAll(
            '.dropdown select, .emaildropdown select, .phone select, .multiselect select',
        ),
    );
    const fileuploads = Array.from(form.querySelectorAll('.fileupload'));

    selects.forEach((field) => {
        customSelect(field);
    });

    fileuploads.forEach((field) => {
        customFileUpload(field);
    });
};

export const defaults = () => {
    const forms = Array.from(document.querySelectorAll('.form'));

    forms.forEach((form) => {
        setupForm(form);
    });
};
