/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { find } from 'lodash';

const FROM_DATE = Number(moment().startOf('day').toDate());
const TO_DATE = Number(moment().endOf('day').add(3, 'month').toDate());

const unixToDate = (unix) => moment(unix, 'X').toDate();
const dateToUnix = (date) => parseInt(moment(date).format('X'), 10);

const DateRange = (renderFn, unmountFn) => (widgetParams = {}) => {
    const { attribute } = widgetParams;

    if (!attribute) {
        throw new Error('Missing attribute argument.');
    }

    return {
        getConfiguration() {
            return {
                disjunctiveFacets: [attribute],
            };
        },

        init({ helper, instantSearchInstance }) {
            this._refine = ({ min, max }) => {
                if (min) {
                    helper.removeNumericRefinement(attribute, '>=');
                    helper.addNumericRefinement(attribute, '>=', dateToUnix(min));
                }

                if (max) {
                    helper.removeNumericRefinement(attribute, '<=');
                    helper.addNumericRefinement(attribute, '<=', dateToUnix(max));
                }
                helper.search();
            };

            this._refine({ min: FROM_DATE, max: TO_DATE });

            renderFn(
                {
                    currentRefinements: { min: null, max: null },
                    refine: this._refine,
                    instantSearchInstance,
                    widgetParams,
                },
                true,
            );
        },

        render({ results, instantSearchInstance }) {
            const minRef = find(results.getRefinements(attribute), { attributeName: attribute, operator: '>=' });
            const min = minRef ? unixToDate(minRef.numericValue) : null;

            const maxRef = find(results.getRefinements(attribute), { attributeName: attribute, operator: '<=' });
            const max = maxRef ? unixToDate(maxRef.numericValue) : null;

            renderFn(
                {
                    currentRefinements: { min, max },
                    refine: this._refine,
                    instantSearchInstance,
                    widgetParams,
                },
                false,
            );
        },

        dispose({ state }) {
            unmountFn();

            const nextState = state
                .removeNumericRefinement(attribute)
                .removeDisjunctiveFacet(attribute);

            return nextState;
        },
    };
};

export default DateRange;
