<template>
    <div ref="search">
        <!-- DEBUG -->
        <!-- <ais-current-refinements /> -->

        <div class="p-search__query mb-base">
            <ais-search-box placeholder="Search for…" />
        </div>

        <ais-state-results>
            <div v-show="!hits.length" slot-scope="{ hits }" class="p-search__noresults">
                <img :src="compass" alt="Compass">
                <p>
                    Oops, looks like there aren’t any results for your search.
                    Try changing your search filters to keep exploring!
                </p>
            </div>
        </ais-state-results>

        <ais-hits>
            <template slot="item" slot-scope="{ item }">
                <ProductCard :item="item" />
            </template>
        </ais-hits>

        <ais-pagination :show-first="false" :show-last="false" @page-change="scrollTop" />
    </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import ProductCard from './components/ProductCard.vue';

export default {
    components: {
        ProductCard,
    },

    mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],

    computed: {
        compass() {
            return `/themes/${this.$root.theme}/images/compass.svg`;
        },
    },

    methods: {
        scrollTop() {
            const el = this.$refs.search;
            el.scrollIntoView();
        },
    },
};
</script>
