<template>
    <div v-if="state" class="date-select">
        <datepicker
            :value="state.currentRefinements.min"
            format="dd/MM/yy"
            @selected="state.refine({ min: $event })"
        >
            <svg
                slot="afterDateInput"
                class="o-Icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <title>Calendar</title>
                <!-- eslint-disable-next-line max-len -->
                <path d="M19 3h-2v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-6v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-2c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-14c0-1.7-1.3-3-3-3zM5 5h2v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h6v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h2c0.6 0 1 0.4 1 1v3h-16v-3c0-0.6 0.4-1 1-1zM19 21h-14c-0.6 0-1-0.4-1-1v-9h16v9c0 0.6-0.4 1-1 1z"></path>
            </svg>
        </datepicker>
        <span class="date-select__divide">to</span>
        <datepicker
            :value="state.currentRefinements.max"
            format="dd/MM/yy"
            @selected="state.refine({ max: $event })"
        >
            <svg
                slot="afterDateInput"
                class="o-Icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <title>Calendar</title>
                <!-- eslint-disable-next-line max-len -->
                <path d="M19 3h-2v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-6v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-2c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-14c0-1.7-1.3-3-3-3zM5 5h2v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h6v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h2c0.6 0 1 0.4 1 1v3h-16v-3c0-0.6 0.4-1 1-1zM19 21h-14c-0.6 0-1-0.4-1-1v-9h16v9c0 0.6-0.4 1-1 1z"></path>
            </svg>
        </datepicker>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { createWidgetMixin } from 'vue-instantsearch';
import connectDateRange from '../connectors/DateRange';

export default {
    components: {
        Datepicker,
    },

    mixins: [createWidgetMixin({ connector: connectDateRange })],

    props: {
        attribute: {
            type: String,
            required: true,
        },
    },

    computed: {
        widgetParams() {
            return {
                attribute: this.attribute,
            };
        },
    },

};
</script>
