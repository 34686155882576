<template>
    <ul v-if="state" class="ais-Menu-list">
        <li class="ais-Menu-item" :class="isOnInitalState">
            <a
                href="/search"
                class="ais-Menu-link"
                @click.prevent="state.refine(null)"
            >
                <span class="ais-Menu-label">All</span>
            </a>
        </li>

        <li
            v-for="item in state.items"
            :key="item.value"
            class="ais-Menu-item"
            :class="{ 'ais-Menu-item--selected' : item.isRefined }"
        >
            <a :href="state.createURL(item.value)" class="ais-Menu-link" @click.prevent="state.refine(item.value)">
                <span class="ais-Menu-label">{{ item.label }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectMenu } from 'instantsearch.js/es/connectors';
import { sortBy } from 'lodash';

export default {
    mixins: [
        createWidgetMixin({ connector: connectMenu }),
    ],

    props: {
        attribute: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            staticCategories: JSON.parse(this.$root.categories),
        };
    },

    computed: {

        widgetParams() {
            return {
                attribute: this.attribute,
                transformItems: this.getStaticValues,
            };
        },

        isOnInitalState() {
            const isRefined = this.state.items.filter((item) => item.isRefined);
            return !isRefined.length ? 'ais-Menu-item--selected' : null;
        },
    },

    methods: {
        transformMenu(items) {
            return sortBy(items, ['label']);
        },

        getStaticValues(items) {
            return this.staticCategories.map((value) => {
                const item = items.find((i) => i.label === value);
                return item || {
                    label: value,
                    value,
                    count: 0,
                    isRefined: false,
                    highlighted: value,
                };
            });
        },
    },
};
</script>
