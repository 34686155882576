<template>
    <a class="o-Poster" :href="url">
        <!-- Image -->
        <img
            v-if="image"
            :data-srcset="image"
            :alt="item.title"
            class="o-Poster__Image o-Image lazyload"
            width="367"
            height="250"
        >
        <img
            v-else
            :src="fallbackImage"
            :alt="item.title"
            class="o-Poster__Image o-Image"
        >

        <div class="o-Poster__content">
            <h6
                v-if="item['roam_products_categories.lvl0']"
                class="o-Poster__meta h6"
            >
                {{ item['roam_products_categories.lvl0'][0] }}
            </h6>
            <h4 class="o-Poster__title h4">{{ item.title }} </h4>
            <p
                v-if="truncatedDescription"
                class="o-Poster__summary small"
            >
                {{ truncatedDescription }}
            </p>
            <div v-if="deal">{{ deal }}</div>
        </div>
    </a>
</template>

<script>
import { truncate } from 'lodash';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        image() {
            let img = '';
            // Need to account for old and new deals fields
            if (this.item.roam_deals_image) {
                img = this.item.roam_deals_image && this.item.roam_deals_image.join(', ');
            } else if (this.item.roam_deals_product_images) {
                img = (
                    this.item.roam_deals_product_images
                    && this.item.roam_deals_product_images.join(', ')
                );
            } else {
                img = 'https://dummyimage.com/300x370/4E562B/fff.png&text=placeholder';
            }
            return img;
        },
        url() {
            return `${this.item.roam_deals_product_url}#deals`;
        },
        deal() {
            let output = '';
            const { local: type } = this.item.roam_deals_type;
            if (type) {
                if (type.label) {
                    output = this.dealText(type.label);
                } else {
                    output = this.dealText(type);
                }
            }
            return output;
        },
        dealText(input) {
            let output = '';
            switch (input.toLowerCase()) {
            case 'specialrate':
                output = 'Special Rate';
                break;
            case 'bonusoffer':
                output = 'Offer';
                break;
            default:
                output = input;
            }
            return output;
        },
        truncatedDescription() {
            let output = '';
            if (this.item.roam_products_description) {
                output = truncate(
                    this.item.roam_products_description.replace(/(<([^>]+)>)/ig, ''),
                    { length: 100, separator: /,? +/ },
                );
            }
            return output;
        },
    },
};
</script>
