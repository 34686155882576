<template>
    <div ref="search">
        <!-- DEBUG -->
        <!-- <ais-current-refinements /> -->

        <div class="p-search__query">
            <ais-search-box placeholder="Search for…" />
            <span>in</span>
            <ais-menu-select
                v-slot="{ items, canRefine, refine }"
                attribute="roam_products_regions.lvl0"
            >
                <Select
                    :items="items"
                    :canRefine="canRefine"
                    :refine="refine"
                    placeholder="Everywhere"
                />
            </ais-menu-select>
        </div>

        <div class="p-search__filters">
            <RoamMenu attribute="roam_products_categories.lvl0" />
            <RoamDateSelect v-if="isEvents" attribute="roam_products_eventDates" />
        </div>

        <ais-state-results>
            <div v-show="!hits.length" slot-scope="{ hits }" class="p-search__noresults">
                <img :src="compass" alt="Compass">
                <p>
                    Oops, looks like there aren’t any results for your search.
                    Try changing your search filters to keep exploring!
                </p>
            </div>
        </ais-state-results>

        <ais-hits>
            <template slot="item" slot-scope="{ item }">
                <ProductCard :item="item" />
            </template>
        </ais-hits>

        <ais-pagination :show-first="false" :show-last="false" @page-change="scrollTop" />
    </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import { find, get } from 'lodash';
import RoamMenu from './components/Menu.vue';
import RoamDateSelect from './components/DateSelect.vue';
import ProductCard from './components/ProductCard.vue';
import Select from './components/Select.vue';

export default {
    components: {
        RoamMenu,
        RoamDateSelect,
        ProductCard,
        Select,
    },

    mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],

    computed: {
        isEvents() {
            const facets = get(this.state, 'items', null);
            if (!facets) return false;

            const facet = find(facets, ['attribute', 'roam_products_categories.lvl0']);
            if (!facet) return false;

            const eventsCategory = find(facet.refinements, ['value', 'What\'s On']);

            return !!eventsCategory;
            // return facet && facet == 'What\'s On';
        },

        compass() {
            return `/themes/${this.$root.theme}/images/compass.svg`;
        },
    },

    methods: {
        scrollTop() {
            const el = this.$refs.search;
            el.scrollIntoView();
        },
    },
};
</script>
