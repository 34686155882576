export default (() => {
    const mobileNav = document.querySelector('.js-mobile-navigation');

    if (mobileNav) {
        const pageLinks = Array.from(mobileNav.querySelectorAll('[data-page-link]'));
        const backs = Array.from(mobileNav.querySelectorAll('[data-back]'));

        pageLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();

                const page = mobileNav.querySelector(`[data-page="${link.dataset.pageLink}"]`);
                page.classList.add('is-active');
            });
        });

        backs.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const page = link.closest('[data-page]');
                page.classList.remove('is-active');
            });
        });
    }
})();
