import { switchLogo } from '../utils';

export default (() => {
    const items = Array.from(document.querySelectorAll('.c-Navigation__item--has-children'));
    const header = document.querySelector('.c-Header');
    const swapBodyClass = (index) => {
        const toRemove = Array.from(document.body.classList).filter((klass) => klass.includes('nav-active'));
        if (toRemove.length) document.body.classList.remove(...toRemove);
        if (index) document.body.classList.add('desktop-nav-active', `nav-active-${index}`);
    };
    let timeout;

    items.forEach((item) => {
        item.addEventListener('mouseenter', () => {
            clearTimeout(timeout);
            // Remove existing active classes
            swapBodyClass(item.dataset.index);
            switchLogo('dark');
        });

        item.addEventListener('mouseleave', () => {
            timeout = setTimeout(() => {
                swapBodyClass();
                if (header.classList.contains('c-Header--not-top')) {
                    switchLogo('dark');
                } else {
                    switchLogo('initial');
                }
            }, 250);
        });
    });
})();
