var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"search"},[_c('div',{staticClass:"p-search__query"},[_c('ais-search-box',{attrs:{"placeholder":"Search for…"}}),_vm._v(" "),_c('span',[_vm._v("in")]),_vm._v(" "),_c('ais-menu-select',{attrs:{"attribute":"roam_products_regions.lvl0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var canRefine = ref.canRefine;
var refine = ref.refine;
return [_c('Select',{attrs:{"items":items,"canRefine":canRefine,"refine":refine,"placeholder":"Everywhere"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"p-search__filters"},[_c('RoamMenu',{attrs:{"attribute":"roam_products_categories.lvl0"}}),_vm._v(" "),(_vm.isEvents)?_c('RoamDateSelect',{attrs:{"attribute":"roam_products_eventDates"}}):_vm._e()],1),_vm._v(" "),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.hits;
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!hits.length),expression:"!hits.length"}],staticClass:"p-search__noresults"},[_c('img',{attrs:{"src":_vm.compass,"alt":"Compass"}}),_vm._v(" "),_c('p',[_vm._v("\n                Oops, looks like there aren’t any results for your search.\n                Try changing your search filters to keep exploring!\n            ")])])}}])}),_vm._v(" "),_c('ais-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('ProductCard',{attrs:{"item":item}})]}}])}),_vm._v(" "),_c('ais-pagination',{attrs:{"show-first":false,"show-last":false},on:{"page-change":_vm.scrollTop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }