<template>
    <ais-instant-search :index-name="selectedIndex" :search-client="searchClient" :routing="routing">
        <ais-configure
            :hits-per-page.camel="24"
            :numeric-refinements.camel="{ ...numericRefinements }"
        />
        <roam-search :selected-index="selectedIndex" @swap-index="selectedIndex = $event" />
    </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { debounce } from 'lodash';
import RoamSearch from './DealsListings.vue';

const pushToGtm = debounce((url) => {
    if ('dl' in window) {
        window.dl.push({
            event: 'search',
            parameters: url,
        });
    }
}, 1000);

const routing = {
    router: historyRouter(),
    stateMapping: {
        stateToRoute({ query, menu, page, range }) {
            return {
                query,
                page,
                category: menu && menu['roam_products_categories.lvl0'],
                region: menu && menu['roam_products_regions.lvl0'],
                dates: range && range.roam_products_eventDates,
            };
        },
        routeToState({ query, category, region, page, dates }) {
            return {
                query,
                page,
                menu: {
                    'roam_products_categories.lvl0': category,
                    'roam_products_regions.lvl0': region,
                },
                range: {
                    roam_products_eventDates: dates,
                },
            };
        },
    },
};

const write = routing.router.write.bind(routing.router);
routing.router.write = (routeState) => {
    write(routeState);
    pushToGtm(routing.router.createURL(routeState));
};

export default {
    components: {
        RoamSearch,
    },

    data() {
        return {
            searchClient: algoliasearch(window.algolia.id, window.algolia.key),
            routing,
            dates: null,
            selectedIndex: 'deals_default',
        };
    },

    computed: {
        numericRefinements() {
            return {
                roam_products_eventDates: this.dates,
            };
        },
    },
};
</script>
