<template>
    <a class="o-Poster" :href="item.roam_products_url">
        <!-- Image -->
        <img
            v-if="srcset"
            :data-srcset="srcset"
            :alt="item.title"
            class="o-Poster__Image o-Image lazyload"
            width="367"
            height="250"
        >
        <img
            v-else
            :src="fallbackImage"
            :alt="item.title"
            class="o-Poster__Image o-Image"
        >

        <div class="o-Poster__content">
            <h6
                v-if="item['roam_products_categories.lvl0']"
                class="o-Poster__meta h6"
            >
                {{ item['roam_products_categories.lvl0'][0] }}
            </h6>
            <h4 class="o-Poster__title h4">{{ item.title }} </h4>
            <p class="o-Poster__summary small">{{ truncatedDescription }}</p>
        </div>
    </a>
</template>

<script>
import { truncate } from 'lodash';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            fallbackImage: window.fallbackImage,
        };
    },

    computed: {
        srcset() {
            const images = this.item.roam_products_images || this.item.roam_common_pageHeader_image;
            return images && images.join(', ');
        },

        truncatedDescription() {
            if (this.item.roam_products_description) {
                return truncate(
                    this.item.roam_products_description.replace(/(<([^>]+)>)/ig, ''),
                    { length: 100, separator: /,? +/ },
                );
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped></style>
