/* global fbq */

const fbWebsite = [...document.querySelectorAll('.fb-operator-website')];
const fbEmail = [...document.querySelectorAll('.fb-operator-email')];
const fbPhone = [...document.querySelectorAll('.fb-operator-phone')];

fbWebsite.forEach((website) => {
    website.addEventListener('click', () => {
        fbq('track', 'Website');
    });
});


fbEmail.forEach((email) => {
    email.addEventListener('click', () => {
        fbq('track', 'Email');
    });
});


fbPhone.forEach((phone) => {
    phone.addEventListener('click', () => {
        fbq('track', 'Phone');
    });
});
