<template>
    <Multiselect
        label="label"
        :value="selected"
        :options="options"
        :searchable="false"
        :show-labels="false"
        :disabled="!canRefine"
        @input="onChange"
    />
</template>

<script>
import { find } from 'lodash';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    components: {
        Multiselect,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        canRefine: {
            type: Boolean,
            required: true,
        },
        refine: {
            type: Function,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'All',
        },
    },

    data() {
        return {
            allOption: { label: this.placeholder, value: '' },
        };
    },

    computed: {
        selected() {
            return find(this.items, ['isRefined', true]) || this.allOption;
        },

        options() {
            return [
                this.allOption,
                ...this.items,
            ];
        },
    },

    methods: {
        onChange({ value }) {
            this.refine(value);
        },
    },
};
</script>
