<template>
    <div ref="search">
        <!-- DEBUG -->
        <!-- <ais-current-refinements /> -->

        <div class="p-search__query">
            <ais-search-box placeholder="Search for…" />
            <span>in</span>
            <ais-menu-select
                v-slot="{ items, canRefine, refine }"
                attribute="roam_products_regions.lvl0"
            >
                <Select
                    :items="items"
                    :canRefine="canRefine"
                    :refine="refine"
                    placeholder="Everywhere"
                />
            </ais-menu-select>
        </div>

        <div class="p-search__filters">
            <RoamMenu attribute="roam_products_categories.lvl0" />
        </div>

        <ais-state-results>
            <div v-show="!hits.length" slot-scope="{ hits }" class="p-search__noresults">
                <img :src="compass" alt="Compass">
                <p>
                    Oops, looks like there aren’t any results for your search.
                    Try changing your search filters to keep exploring!
                </p>
            </div>
        </ais-state-results>

        <ais-hits>
            <template slot="item" slot-scope="{ item }">
                <Deal :item="item" />
            </template>
        </ais-hits>

        <ais-pagination :show-first="false" :show-last="false" @page-change="scrollTop" />
    </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import RoamMenu from './components/Menu.vue';
import Deal from './components/Deal.vue';
import Select from './components/Select.vue';

export default {
    components: {
        RoamMenu,
        Deal,
        Select,
    },

    mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],

    computed: {
        compass() {
            return `/themes/${this.$root.theme}/images/compass.svg`;
        },
    },

    methods: {
        scrollTop() {
            const el = this.$refs.search;
            el.scrollIntoView();
        },
    },
};
</script>
