import Flickity from 'flickity-imagesloaded';
import 'flickity/dist/flickity.css';

const insertImage = (flkty, container) => {
    const images = Array.from(container.children);

    images.forEach((image) => {
        image.classList.remove('c-Featured__Image--selected');
    });

    if (images.length > flkty.selectedIndex) {
        images[flkty.selectedIndex].classList.add('c-Featured__Image--selected');
    }
};

export const featured = ({
    node,
    text = '.c-Featured__carousel',
    images = '.c-Featured__images',
}) => {
    const flkty = new Flickity(node.querySelector(text), {
        draggable: '>1',
        imagesLoaded: true,
        pageDots: true,
        prevNextButtons: false,
        wrapAround: true,
        autoPlay: 5000,
        on: {
            ready() {
                insertImage(this, node.querySelector(images));
            },
            change() {
                insertImage(this, node.querySelector(images));
            },
        },
    });

    return flkty;
};


export const defaults = () => {
    const carousels = Array.from(document.querySelectorAll('.js-featured'));

    carousels.forEach((carousel) => {
        featured({ node: carousel });
    });
};
