if (document.querySelector('.c-Video')) {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

// eslint-disable-next-line func-names
window.onYouTubeIframeAPIReady = function () {
    const videos = Array.from(document.querySelectorAll('.c-Video'));
    videos.forEach((video) => {
        const viewport = video.querySelector('.c-Video__iframe');
        const videoId = viewport.getAttribute('data-id');
        window.player = new window.YT.Player(viewport, {
            videoId,
            enablejsapi: 1,
            playerVars: {
                autoplay: 0,
                controls: 1,
                showinfo: 0,
                modestbranding: 1,
                loop: 0,
                fs: 1,
                cc_load_policy: 0,
                autohide: 0,
            },
        });
    });
};
