// // Images
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
import './youtube-lazyload';

import { browserDetects } from './utils';
import { defaults as forms } from './components/form';
import { defaults as toggles } from './components/toggle';
import { defaults as featured } from './components/featured';
import { defaults as carousels } from './components/carousel';


import './search/index';
import './components/Navigation';
import './components/MobileNavigation';
import './components/subscribe';
import './components/tabs';
import './fb-tracking';
import './stickynav';

browserDetects();
carousels();
forms();
toggles();
featured();

// Hide instagram image if broken
const instagramImages = Array.from(document.querySelectorAll('.o-InstagramImage'));
instagramImages.forEach((image) => {
    const imgTag = image.querySelector('img');
    if (!imgTag) return;
    imgTag.addEventListener('error', () => {
        // eslint-disable-next-line no-param-reassign
        image.style.display = 'none';
    });
});
