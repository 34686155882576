import Vue from 'vue';
import InstantSearch from 'vue-instantsearch';
import App from './InstantSearch.vue';
import Deals from './Deals.vue';

const search = document.querySelector('.roam-search');
const dealscontainer = document.querySelector('.roam-deals-search');
const searchTemplate = dealscontainer ? Deals : App;

if (search) {
    Vue.use(InstantSearch);
    new Vue({
        el: search,
        data: () => Object.assign({}, search.dataset),
        render: (h) => h(searchTemplate),
    });
}
