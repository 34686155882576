import axios from 'axios';

class Newsletter {
    constructor({ form }) {
        this.form = form;
        this.input = form.querySelector('input[type="email"]');
        this.class = form.className;

        this.events();
    }

    events() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();

            this.setState('loading');

            // Convert FormData to JSON
            const data = Array.from(new FormData(this.form).entries()).reduce((memo, pair) => ({
                ...memo,
                [pair[0]]: pair[1],
            }), {});

            axios.post('/newsletter', data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState('success');
                        // this.setMessage(response.data.message);
                        this.setMessage('Thanks for subscribing');
                        this.form.reset();

                        if ('dl' in window) {
                            window.dl.push({
                                event: 'newsletterSubscribe',
                            });
                        }
                    } else {
                        this.setState('invalid');
                        this.setMessage(
                            response.data.message
                            || 'We are having trouble adding you to the list.<br>Have you already subscribed?',
                        );
                    }
                })
                .catch(() => {
                    this.setState('invalid');
                    this.setMessage('Server error. Please try again later.');
                });

            this.form.reset();
            this.form.querySelector('button').blur();
        });
    }

    setState(state) {
        this.form.className = this.class;
        this.form.classList.add(`form--${state}`);

        this.input.addEventListener('keydown', () => {
            this.setState('valid');
        }, { once: true });
    }

    setMessage(message) {
        const messageDiv = this.form.querySelector('.c-Subscribe__message');
        messageDiv.innerHTML = message;
    }
}

export default (() => {
    const subscribeForms = Array.from(document.querySelectorAll('.js-subscribe'));
    subscribeForms.forEach((form) => {
        new Newsletter({ form });
    });
})();
