import { hasTouch } from 'detect-touch';

export const breakpoints = {
    palm: 'screen and (max-width: 600px)',
    lap: 'screen and (min-width: 601px) and (max-width: 959px)',
    portable: 'screen and (max-width: 959px)',
    lapAndUp: 'screen and (min-width: 601px)',
    desk: 'screen and (min-width: 960px)',
    deskWide: 'screen and (min-width: 1075px)',
};


export const switchLogo = (colour) => {
    const logo = document.querySelector('.js-logo');
    if (logo) {
        logo.src = logo.dataset[colour];
    }
};

// CSS Grid and touch feature detect
export const browserDetects = () => {
    const html = document.querySelector('html');

    const el = document.createElement('div');
    if (typeof el.style.grid !== 'string') {
        html.classList.add('no-cssgrid');
    }

    if (!hasTouch) html.classList.add('no-touch');
};

export default breakpoints;
