import Flickity from 'flickity';
import 'flickity-imagesloaded';
import 'flickity-fade';

import 'flickity/dist/flickity.css';
import 'flickity-fade/flickity-fade.css';

export const fader = (node) => {
    const flkty = new Flickity(node, {
        draggable: '>1',
        imagesLoaded: true,
        fade: true,
        contain: false,
        wrapAround: true,
        pageDots: true,
        prevNextButtons: true,
        autoPlay: 5000,
        cellSelector: node.dataset.cells,
    });

    return flkty;
};

export const slider = (node) => {
    const flkty = new Flickity(node, {
        draggable: '>1',
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: true,
        autoPlay: 5000,
        wrapAround: true,
        cellAlign: 'left',
        contain: true,
    });

    return flkty;
};

export const featuredSlider = (node) => {
    const flkty = new Flickity(node, {
        draggable: '>1',
        imagesLoaded: true,
        pageDots: true,
        prevNextButtons: true,
        autoPlay: 5000,
        wrapAround: true,
        cellAlign: 'left',
        contain: true,
    });

    return flkty;
};

export const banner = (node) => {
    const flkty = new Flickity(node, {
        draggable: '>1',
        imagesLoaded: true,
        fade: false,
        contain: false,
        wrapAround: true,
        pageDots: true,
        prevNextButtons: true,
        autoPlay: 5000,
        cellSelector: node.dataset.cells,
        arrowShape: {
            x0: 10,
            x1: 60,
            y1: 50,
            x2: 60,
            y2: 40,
            x3: 60,
        },
    });

    return flkty;
};

export const defaults = () => {
    const faders = Array.from(document.querySelectorAll('.js-fader'));
    const sliders = Array.from(document.querySelectorAll('.js-slider'));
    const featuredSliders = Array.from(document.querySelectorAll('.js-featuredSlider'));
    const banners = Array.from(document.querySelectorAll('.js-banner'));

    faders.forEach((node) => {
        fader(node);
    });

    sliders.forEach((node) => {
        slider(node);
    });

    featuredSliders.forEach((node) => {
        featuredSlider(node);
    });

    banners.forEach((node) => {
        banner(node);
    });
};
