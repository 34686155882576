import Headroom from 'headroom.js';
import { switchLogo } from './utils';

const myElement = document.querySelector('header');
const options = {
    tolerance: {
        up: 5,
        down: 60,
    },
    classes: {
        initial: 'c-Header',
        pinned: 'c-Header--pinned',
        unpinned: 'c-Header--unpinned',
        top: 'c-Header--top',
        notTop: 'c-Header--not-top',
        bottom: 'c-Header--bottom',
        notBottom: 'c-Header--not-bottom',
        frozen: 'c-Header--frozen',
    },
    onNotTop() {
        switchLogo('dark');
        document.body.classList.add('header-not-top');
    },
    onTop() {
        if (document.body.classList.contains('theme--dark')) {
            switchLogo('dark');
        } else {
            switchLogo('light');
        }
        document.body.classList.remove('header-not-top');
    },
};

const stickynav = new Headroom(myElement, options);

stickynav.init();
