/* eslint-disable no-new */

export class Toggle {
    constructor({
        source,
        target = document.querySelector(source.dataset.target),
        sourceClass = source.dataset.sourceClass,
        targetClass = source.dataset.targetClass,
    }) {
        this.source = source;
        this.target = target;
        this.sourceClass = sourceClass;
        this.targetClass = targetClass;

        if (!this.source) return;

        this.source.addEventListener('click', (event) => {
            event.preventDefault();
            this.toggleClasses();
        });
    }

    toggleClasses() {
        if (this.sourceClass) this.source.classList.toggle(this.sourceClass);
        if (this.targetClass && this.target) this.target.classList.toggle(this.targetClass);
    }
}

export const defaults = () => {
    const toggles = Array.from(document.querySelectorAll('.js-toggle'));

    toggles.forEach((el) => {
        new Toggle({ source: el });
    });
};
