export default (() => {
    const tabs = Array.from(document.querySelectorAll('.p-product__tabs li'));
    const sections = Array.from(document.querySelectorAll('.p-product__tab-section'));

    tabs.forEach((tab) => {
        tab.addEventListener('click', () => {
            [...tabs, ...sections].forEach((item) => item.classList.remove('active'));
            const actives = Array.from(document.querySelectorAll(`[data-section=${tab.dataset.section}]`));
            actives.forEach((item) => item.classList.add('active'));
        });
        if (window.location.hash) {
            const hash = window.location.hash.substring(1);
            [...tabs, ...sections].forEach((item) => item.classList.remove('active'));
            const actives = Array.from(document.querySelectorAll(`[data-section=${hash}]`));
            actives.forEach((item) => item.classList.add('active'));
        }
    });
})();
